<template>
  <div>
    <b-table
      :busy="is.loading || relationsIs.loading"
      :fields="tableData.fields"
      :items="tableData.items"
      thead-class="d-none"
      striped
      borderless
      :stacked="$root.mobile.up.key"
      show-empty
      sort-icon-left
      :tbody-tr-class="table.helper.rowClass"
      class="services-table"
    >
      <template #table-busy>
        <div class="text-center">
          <c-loader type="dots" inline scale="4"/>
          <div>{{ $T('Busy') }}</div>
        </div>
      </template>

      <template #cell(subscribed)="data">
        <control-checkbox
        :id="`${$options.name}_Checkbox_${data.item.id}`"
          class="mb-0"
          v-model="data.value"
          switch
          @change="$value => updateRelation(data.field.key, data.item, $value)"
        />
      </template>

      <template #row-details="data">
        <template v-if="data.item.isTitle">
          <h3 class="h5">{{ data.item.title }}</h3>
        </template>
      </template>
    </b-table>
  </div>
</template>

<script>
import Table, { TableHelper } from '@/assets/js/helper/table'

const PARENT_KEY = 'users'
const ENTITY_KEY = 'services'
const RELATION_KEY = 'userservices'

export default {
  name: 'Users.Services',
  data () {
    return {
      parentKey: PARENT_KEY,
      parentId: null,
      entityKey: ENTITY_KEY,
      relationKey: RELATION_KEY,
      table: {
        helper: TableHelper,
        options: {
          fields: {
            includes: [],
            excludes: [],
            sorting: ['subscribed', 'name', 'description'],
            sortable: ['name', 'description'],
            labelKey: 'views.users.services.table.label.{field}',
            label: {},
            variant: {},
            class: {},
            thClass: {},
            tdClass: {}
          }
        }
      }
    }
  },
  computed: {
    parent () {
      return this.$store.getters[`${this.parentKey}/getEntityUnwrapped`](this.parentId)
    },
    is () {
      return this.$store.getters[`${this.entityKey}/is`]
    },
    definition () {
      return this.$store.getters[`${this.entityKey}/definition`]
    },
    entities () {
      return this.$store.getters[`${this.entityKey}/getUnwrapped`]
    },
    relationsIs () {
      return this.$store.getters[`${this.relationKey}/is`]
    },
    relationsDefinition () {
      return this.$store.getters[`${this.relationKey}/definition`]
    },
    relations () {
      return this.$store.getters[`${this.relationKey}/getUnwrapped`]
    },
    tableEntities () {
      let previousCategoryKey = null

      return []
        .concat(this.entities)
        .sort(TableHelper.sortByProperties('category', `name.${this.$store.getters['gui/getLanguage']}`))
        .map(ENTITY => {
          const RELATION = this.relations.find(r => r.subscriptionAndServiceId === ENTITY.id) || {}

          const CATEGORY_KEY = ENTITY.category || null
          const CATEGORY_TRANSLATIONS = ((((this.definition.$properties || {}).category || {}).translations || {})[this.$store.getters['gui/getLanguage']] || {}).selectValues || {}

          const _table = CATEGORY_KEY && CATEGORY_KEY !== previousCategoryKey ? { title: CATEGORY_TRANSLATIONS[CATEGORY_KEY] } : null

          previousCategoryKey = CATEGORY_KEY

          return {
            _table,
            _entity: ENTITY,
            _relation: RELATION,
            id: ENTITY.id,
            subscribed: RELATION.subscribed || false,
            name: getTranslatedValue.bind(this)(ENTITY.name),
            description: getTranslatedValue.bind(this)(ENTITY.description),
            hasRelation: RELATION.subscribed !== undefined,
            status: ENTITY.status
          }
        })

      function getTranslatedValue (property = {}) {
        return property[this.$store.getters['gui/getLanguage']]
      }
    },
    tableData () {
      return new Table(this.tableEntities, this.table.options.fields)
    }
  },
  methods: {
    getParent () {
      this.$store.commit(`${this.parentKey}/setLoading`, { loading: true })
      this.$store.dispatch(`${this.parentKey}/getSelf`)
        .then(response => {
          this.parentId = response.value.id
          this.getEntities()
          this.$store.commit(`${this.parentKey}/setLoading`, { loading: false })
        })
    },
    getEntities () {
      this.$store.dispatch(`${this.relationKey}/getAll`, { by: { name: this.parentKey, id: this.parent.id } })
      this.$store.dispatch(`${this.entityKey}/getAll`)
    },
    updateRelation (key, item, value) {
      const action = item.hasRelation ? 'updateEntity' : 'createEntity'
      const id = item.hasRelation ? item._relation.id : undefined

      this.$store.dispatch(`${this.relationKey}/${action}`, {
        id,
        userId: this.parent.id,
        subscriptionAndServiceId: item.id,
        subscribed: value
      })
    }
  },
  created () {
    TableHelper.rowClassHook(item => item.isTitle ? 'bg-dark text-white' : null)
    this.getParent()
  }
}
</script>

<style lang="scss">
$services-stacked-name-font-weight: $font-weight-bold !default;

.table.b-table.b-table-stacked-lg.services-table {
  > tbody {
    > tr {
      > [data-label] {
        &::before {
          display: none;
        }

        > div {
          padding-left: 0;
        }
      }

      > [data-label=name] {
        > div {
          font-weight: $services-stacked-name-font-weight;
        }
      }
    }
  }
}
</style>
